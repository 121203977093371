import CurrencyInput from 'react-currency-input-field';

export const CurrencyInputText = (props) => {
  const applyCallback = (value) => {
    if (props.onChange)
      props.onChange(props.name, value);
  }
  return (
    <>
      {props.readonly ?
        <div className="fake-form-control">{props.value ? props.value.toLocaleString() : 0}</div>
        :
        <CurrencyInput
          className={"form-control " + props.className}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          decimalsLimit={2}
          onValueChange={applyCallback}
        />
      }
    </>
  )
}