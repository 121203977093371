import Axios from 'axios'
import { alertActions } from 'src/_actions'
import { store, storage } from 'src/_helpers'
import { mesageConstants } from 'src/_constants'
import NProgress from 'nprogress'
import {
  isMobile,
  isTablet,
  isBrowser,
  isDesktop,
  isAndroid,
  isWinPhone,
  isIOS,
  isChrome,
  isFirefox,
  isSafari
  // isOpera,
  // isIE,
  // isEdge,
  // isMobileSafari,
  // isSamsungBrowser,
  // osVersion,
  // osName,
  // fullBrowserVersion,
  // browserVersion,
  // browserName
} from "react-device-detect";
class BaseService {
  /**
   * The constructor of the BaseService.
   *
   * @param {string} endpoint   The endpoint being used.
   * @param {Object} parameters The parameters for the request.
   */
  constructor(endpoint, parameters = {}) {
    this.endpoint = endpoint
    this.parameters = parameters
    this.hideLoading = false
    this.hideError = false
    // this.config = {}
    this.setHeader()
  }
  setHeader() {
    // let Token = storage.get('Token')
    // if (Token) {
    //   Axios.defaults.headers.common['Authorization'] = `${Token}`
    // }
  }
  /**
   * Method used to set the query parameters.
   *
   * @param {Object} parameters The given parameters.
   *
   * @returns {BaseService} The instance of the proxy.
   */
  setParameters(parameters) {
    Object.keys(parameters).forEach((key) => {
      this.parameters[key] = parameters[key]
    })

    return this
  }

  /**
   * Method used to set a single parameter.
   *
   * @param {string} parameter The given parameter.
   * @param {*} value The value to be set.
   *
   * @returns {BaseService} The instance of the proxy.
   */
  setParameter(parameter, value) {
    this.parameters[parameter] = value

    return this
  }

  /**
   * Method used to remove all the parameters.
   *
   * @param {Array} parameters The given parameters.
   *
   * @returns {BaseService} The instance of the proxy.
   */
  removeParameters(parameters) {
    parameters.forEach((parameter) => {
      delete this.parameters[parameter]
    })

    return this
  }

  /**
   * Method used to remove a single parameter.
   *
   * @param {string} parameter The given parameter.
   *
   * @returns {BaseService} The instance of the proxy.
   */
  removeParameter(parameter) {
    delete this.parameters[parameter]
    return this
  }
  noLoading() {
    this.hideLoading = true
    return this
  }
  noError() {
    this.hideError = true
    return this
  }
  loading() {
    if (!this.hideLoading) NProgress.start()
  }
  loadingEnd() {
    setTimeout(() => {
      NProgress.done()
    }, 500)
  }
  /**
   * The method used to perform an AJAX-request.
   *
   * @param {string}      requestType The request type.
   * @param {string}      url         The URL for the request.
   * @param {Object|null} data        The data to be send with the request.
   *
   * @returns {Promise} The result in a promise.
   */
  submit(requestType, url, data = null, baseURL) {
    this.loading()
    // this.setParameter('_currrentUrl', Vue.router.currentRoute.fullPath)
    var option = { useCredentails: true }
    if (baseURL) option.baseURL = baseURL
    return new Promise((resolve, reject) => {
      Axios[requestType](url + this.getParameterString(), data, option)
        .then((response) => {
          !this.hideLoading && this.loadingEnd()
          resolve(response.data)
        })
        .catch(({ response }) => {
          this.loadingEnd()
          this.handleErrorResponse(response)
          reject(response)
        })
    })
  }
  sendRequestWithBaseUrl(requestType, url, data = null, baseURL) {
    this.loading()
    // this.setParameter('_currrentUrl', Vue.router.currentRoute.fullPath)
    return new Promise((resolve, reject) => {
      Axios({
        method: requestType,
        url: url + this.getParameterString(),
        baseURL: baseURL,
        data
      }).then((response) => {
        this.loadingEnd()
        resolve(response.data)
      })
        .catch(({ response }) => {
          this.loadingEnd()
          this.handleErrorResponse(response)
          reject(response)
        })
    })

  }
  getAllFromStorage(key, focus) {
    return new Promise((resolve) => {
      var fromStorage = storage.get(key)
      if (fromStorage && !focus) {
        return resolve(fromStorage)
      } else {
        return this.submit('get', `/${this.endpoint}/`).then((response) => {
          storage.set(key, (response))
          resolve(response)
        })
      }
    })
  }
  handleErrorResponse(error) {
    if (this.hideError) return
    if (error) {
      // switch (error.status) {
      //   case 400:
      //     this._404(error.data.ViMessage)
      //     break;
      //   case 404:
      //     this._404(error.data.ViMessage)
      //     break;
      //   default:
      //     break;
      // }
      let { messages } = error.data
      if (typeof messages === 'object') {
        messages.forEach(msg => {
          store.dispatch(alertActions.error(msg))
        })
      } else {
        store.dispatch(alertActions.error(messages || mesageConstants.ERROR))
      }
    } else {
      store.dispatch(alertActions.error(mesageConstants.ERROR))
    }
  }
  _404(msg) {
    // history.push({
    //   pathname: '/404',
    //   search: '?error=' + msg
    // })
  }
  /**
   * Method used to fetch all items from the API.
   *
   * @returns {Promise} The result in a promise.
   */
  all() {
    return this.submit('get', `/${this.endpoint}/`)
  }
  all2() {
    return this.submit('get', `/${this.endpoint}`)
  }

  /**
   * Method used to fetch a single item from the API.
   *
   * @param {int} id The given identifier.
   *
   * @returns {Promise} The result in a promise.
   */
  find(id) {
    return this.submit('get', `/${this.endpoint}/${id}`)
  }

  /**
   * Method used to create an item.
   *
   * @param {Object} item The given item.
   *
   * @returns {Promise} The result in a promise.
   */
  create(item) {
    return this.submit('post', `${this.endpoint}/`, item)
  }

  /**
   * Method used to update an item.
   *
   * @param {int}    id   The given identifier.
   * @param {Object} item The given item.
   *
   * @returns {Promise} The result in a promise.
   */
  update(id, item) {
    return this.submit('post', `/${this.endpoint}/${id}`, item)
  }
  updateOrCreate(item) {
    if (item.id) return this.update(item.id, item)
    return this.create(item)
  }
  putOrCreate(item) {
    if (item.id) return this.put(item.id, item)
    return this.create(item)
  }
  put(id, item) {
    return this.submit('put', `/${this.endpoint}/${id}`, item)
  }
  patch(id, item) {
    return this.submit('patch', `/${this.endpoint}/${id}`, item)
  }
  /**
   * Method used to destroy an item.
   *
   * @param {int} id The given identifier.
   *
   * @returns {Promise} The result in a promise.
   */
  destroy(id) {
    return this.submit('delete', `/${this.endpoint}/${id}`)
  }

  /**
   * Method used to transform a parameters object to a parameters string.
   *
   * @returns {string} The parameter string.
   */
  getParameterString() {
    var info = {
      isMobile,
      isTablet,
      isBrowser,
      isDesktop,
      isAndroid,
      isWinPhone,
      isIOS,
      isChrome,
      isFirefox,
      isSafari
    }
    var parameters = {...this.parameters, ...info}
    const keys = Object.keys(parameters)
    var parameterStrings = keys
      .filter(key => !!parameters[key])
      .map(key => `${key}=${parameters[key]}`)
    // parameterStrings.push(`_currentRoute=${window.location.hash}`)
    return parameterStrings.length === 0 ? '' : `?${parameterStrings.join('&')}`
  }
}

export default BaseService
