import React from 'react'
import Select from 'react-select'
export const SelectBox = (props) => {
  // const [defaultValue] = useState(props.value || 0);
  const applyCallback = (value) => {
    if (props.onChange)
      props.onChange({
        target: {
          name: props.name,
          value: value.value
        }
      });
  }
  const objValue = props.options.find(e => e.value === props.value)
  return (
    <>
      {props.readonly ?
        <div className="">{objValue ? objValue.label : 'NA'}</div>
        :
        <Select
          className="zixndex1"
          value={objValue}
          options={props.options}
          name={props.name}
          onChange={applyCallback}
        />
      }
    </>
  )
}