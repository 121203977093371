import {
  CDataTable,
  CPagination
} from "@coreui/react";
import { NoData } from "src/_components";
export const DataTable = (props) => {
  return (
    <>
      <CDataTable
        noItemsViewSlot={ <NoData/> }
        items={props.results || []}
        fields={props.fields}
        hover
        striped
        bordered
        scopedSlots={props.scopedSlots}
      />
      <CPagination
        activePage={props.activePage}
        onActivePageChange={props.onActivePageChange.bind(this)}
        pages={props.pages}
        doubleArrows={true}
        align="start"
      />
    </>
  )
}