import BaseService from './base.service'
export class NotificationService extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super('api/notification', parameters)
  }
  seen(id) {
    return this.update('seen', {
      ids: [id]
    })
  }
  seenAll() {
    return this.update('seen', {
      ids: [],
      all: true
    })
  }
}
