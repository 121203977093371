import BaseService from './base.service'
export class ArticleService extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}, type = "network") {
    super(`api/${type}/article`, parameters)
  }
}