import {
  strDateToMoment
} from "src/_helpers"
const COLORS = [
  '#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba'
];
// function randomNumberArray (len) {
//   var arr = []
//   for (let index = 0; index < len; index++) {
//     arr.push(Math.floor(Math.random() * 99))
//   }
//   return arr
// }
export function randomColor (index) {
  return COLORS[index] || '#' + Math.floor(Math.random()*16777215).toString(16);
}
function mapQrAnalytics (Labels, Analytics, gid) {
  var format = 'HH:mm DD/MM/YYYY'
  var type = Labels[0].type
  var dataChart = []
  for (let index = 0; index < Labels.length; index++) {
    if (type === 'ByWeek') {
      let startDate = strDateToMoment('00:00 ' + Labels[index].start, format)
      let endDate = strDateToMoment('23:59 ' + Labels[index].end, format)
      if (index > 0) startDate = startDate.add(1, 'days')
      dataChart.push(Analytics.filter(e => gid === e.refId && e._datetime >= startDate && e._datetime <= endDate).length)
    }
    if (type === 'ByMonth') {
      dataChart.push(Analytics.filter(e => gid === e.refId && Labels[index].display === e._month).length)
    }
    if (type === 'ByDate') {
      dataChart.push(Analytics.filter(e => gid === e.refId && Labels[index].start === e._date).length)
    }
  }
  return dataChart
}
export function scanQrByAnalytics (SrcData, Labels, Analytics) {
  var dataChart = []
  for (let index = 0; index < SrcData.length; index++) {
    dataChart.push({
      label: SrcData[index].label,
      data: mapQrAnalytics(Labels, Analytics, SrcData[index].value),
      fill: false,
      borderJoinStyle: "miter",
      lineTension: 0,
      backgroundColor: SrcData[index].color,
      borderColor: SrcData[index].color,
      borderDash: [],
      borderDashOffset: 0.0
    })
  }
  return dataChart
}
function calRevenue (Labels, Analytics, refId) {
  var format = 'HH:mm DD/MM/YYYY'
  var type = Labels[0].type
  var dataChart = []
  for (let index = 0; index < Labels.length; index++) {
    if (type === 'ByWeek') {
      let startDate = strDateToMoment('00:00 ' + Labels[index].start, format)
      let endDate = strDateToMoment('23:59 ' + Labels[index].end, format)
      if (index > 0) startDate = startDate.add(1, 'days')
      dataChart.push(Analytics.filter(e => refId === e.refId && e._datetime >= startDate && e._datetime <= endDate).reduce((accumulator, object) => { return accumulator + parseInt(object.note || '0') }, 0))
    }
    if (type === 'ByMonth') {
      dataChart.push(Analytics.filter(e => refId === e.refId && Labels[index].display === e._month).reduce((accumulator, object) => { return accumulator + parseInt(object.note || '0') }, 0))
    }
    if (type === 'ByDate') {
      dataChart.push(Analytics.filter(e => refId === e.refId && Labels[index].start === e._date).reduce((accumulator, object) => { return accumulator + parseInt(object.note || '0') }, 0))
    }
  }
  return dataChart
}
export function revenueByMenu(SrcData, Labels, Analytics) {
  var dataChart = []
  for (let index = 0; index < SrcData.length; index++) {
    dataChart.push({
      label: SrcData[index].label,
      data: calRevenue(Labels, Analytics, SrcData[index].value),
      backgroundColor: SrcData[index].color
    })
  }
  return dataChart
}
export function PerRevenueByMenu (SrcData, Analytics) {
  var dataChart = []
  var total = Analytics.filter(e => e.refType === 'ORDERBYMENU').reduce((accumulator, object) => { return accumulator + parseInt(object.note || '0') }, 0)
  for (let index = 0; index < SrcData.length; index++) {
    var t = Analytics.filter(e => SrcData[index].value === e.refId && e.refType === 'ORDERBYMENU').reduce((accumulator, object) => { return accumulator + parseInt(object.note || '0') }, 0)
    dataChart.push((t / total * 100).toFixed(2))
  }
  return dataChart
}
function calProgressTime (Labels, Analytics, refId, outOfTime) {
  var AnalyticsData = Analytics.filter(e => e.refType === 'ORDERCHAGESTATUS_DONE' && (!outOfTime || parseInt(e.note || '0') >= outOfTime))
  var format = 'HH:mm DD/MM/YYYY'
  var type = Labels[0].type
  var dataChart = []
  for (let index = 0; index < Labels.length; index++) {
    if (type === 'ByWeek') {
      let startDate = strDateToMoment('00:00 ' + Labels[index].start, format)
      let endDate = strDateToMoment('23:59 ' + Labels[index].end, format)
      if (index > 0) startDate = startDate.add(1, 'days')
      let listData = AnalyticsData.filter(e => (!refId || refId === e.refId) && e._datetime >= startDate && e._datetime <= endDate)
      let totalItem = listData.length
      if (outOfTime) {
        dataChart.push(totalItem)
      } else {
        let sum = listData.reduce((accumulator, object) => { return accumulator + parseInt(object.note || '0') }, 0)
        dataChart.push(totalItem ? (sum / totalItem) : 0)
      }
    }
    if (type === 'ByMonth') {
      let listData = AnalyticsData.filter(e => (!refId || refId === e.refId) && Labels[index].display === e._month)
      let totalItem = listData.length
      let sum = listData.reduce((accumulator, object) => { return accumulator + parseInt(object.note || '0') }, 0)
      if (outOfTime) {
        dataChart.push(totalItem)
      } else {
        dataChart.push(totalItem ? (sum / totalItem) : 0)
      }
    }
    if (type === 'ByDate') {
      let listData = AnalyticsData.filter(e => (!refId || refId === e.refId) && Labels[index].start === e._date)
      let totalItem = listData.length
      let sum = listData.reduce((accumulator, object) => { return accumulator + parseInt(object.note || '0') }, 0)
      if (outOfTime) {
        dataChart.push(totalItem)
      } else {
        dataChart.push(totalItem ? (sum / totalItem) : 0)
      }
      // dataChart.push(Analytics.filter(e => refId === e.refId && Labels[index].start === e._date).reduce((accumulator, object) => { return accumulator + parseInt(object.note || '0') }, 0))
    }
  }
  return dataChart
}
export function progressTime (SrcData, Labels, Analytics, outOfTime = 0) {
  var dataChart = []
  for (let index = 0; index < SrcData.length; index++) {
    dataChart.push({
      label: SrcData[index].label,
      data: calProgressTime(Labels, Analytics, SrcData[index].value, outOfTime),
      fill: false,
      borderJoinStyle: "miter",
      lineTension: 0,
      backgroundColor: SrcData[index].color,
      borderColor: SrcData[index].color,
      borderDash: [],
      borderDashOffset: 0.0
    })
  }
  return dataChart
}