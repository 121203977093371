import { cloneObj } from 'src/_helpers'
let cartData = []
try {
  cartData = JSON.parse(localStorage.getItem('cart'))
} catch {
  cartData = []
}
const initialState = cartData || []
function getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}
const formatCart = (cartData) => {
  var fData = cloneObj(cartData)
  var fData2 = cloneObj(cartData)
  return getUniqueListBy(fData.filter(e => e.qty).map(e => {
    var filterKey = JSON.stringify(e._key)
    e._f = filterKey
    var qty = fData2.filter(fc => JSON.stringify(fc._key) === filterKey).reduce((accumulator, object) => { return accumulator + object.qty }, 0)
    e.qty = qty
    return e
  }), '_f')
}
export function cart(state = initialState, action) {
  var newState = Object.assign([], state)
  switch (action.type) {
    case 'updateItem':
      newState = newState.map(ite => {
        if (ite._id === action.item._id || JSON.stringify(ite._key) === JSON.stringify(action.item._key)) {
          return {...action.item}
        }
        return ite
      })
      var newStupdateItem = formatCart(newState)
      localStorage.setItem('cart', JSON.stringify(newStupdateItem))
      return newStupdateItem;
    case 'addToCart':
      newState.push(action.item)
      var newStaddToCart = formatCart(newState)
      localStorage.setItem('cart', JSON.stringify(newStaddToCart))
      return newStaddToCart;
    case 'removeFromCart':
      newState = newState.filter(e => e.id !== action.id)
      var newStremoveFromCart = formatCart(newState)
      localStorage.setItem('cart', JSON.stringify(newStremoveFromCart))
      return newStremoveFromCart;
    case 'updateQty':
      newState.forEach(ite => {
        if (ite.id === action.item.id) {
          ite.qty = action.item.qty
        }
      })
      var newStupdateQty = formatCart(newState)
      localStorage.setItem('cart', JSON.stringify(newStupdateQty))
      return newStupdateQty;
    case 'removeFromCartV2':
        newState = newState.filter(e => e.id !== action.item.id && JSON.stringify(e._key) !== JSON.stringify(action.item._key))
        var newStremoveFromCartV2 = formatCart(newState)
        localStorage.setItem('cart', JSON.stringify(newStremoveFromCartV2))
        return newStremoveFromCartV2;
    case 'updateV2Qty':
        newState.forEach(ite => {
          if (JSON.stringify(ite._key) === JSON.stringify(action.item._key)) {
            ite.qty = action.item.qty
          }
        })
        var newStupdateV2Qty = formatCart(newState)
        localStorage.setItem('cart', JSON.stringify(newStupdateV2Qty))
        return newStupdateV2Qty;
    case 'clear':
      localStorage.setItem('cart', [])
      return [];
    default:
      return state
  }
}