import { SiteName, TransText } from "src/_components";
import {
  CLink,
  CBreadcrumb,
  CBreadcrumbItem
} from "@coreui/react";
export const PageBreadcrumb = (props) => {
  return (<CBreadcrumb className="v-breadcrumb">
  <CBreadcrumbItem>
    <CLink to={`/admin`}>
      <SiteName/>
    </CLink>
  </CBreadcrumbItem>
  <CBreadcrumbItem>
    <CLink to={props.to}>
    <TransText>{props.label}</TransText>
    </CLink>
  </CBreadcrumbItem>
</CBreadcrumb>)
}