import React, { Component } from 'react'
import { connect } from 'react-redux'
import { HashRouter, Route, Switch } from 'react-router-dom'
// import { withTranslation } from 'react-i18next'
import { alertActions } from './_actions'
import {
  Loading
} from "src/_components"
import {
  CToast,
  CToastBody,
  CToastHeader,
  CToaster
} from '@coreui/react'
import './scss/style.scss'

import 'src/plugins/axios'
import i18n from 'src/i18n'
const loading = (
  <Loading/>
)

// Containers
const TheLayoutWarranty = React.lazy(() => import('./containers/TheLayoutWarranty'))
const TheAdminLayoutWarranty = React.lazy(() => import('./containers/TheAdminLayoutWarranty'))


const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    const { alert } = this.props
    return (
      <HashRouter>
          <CToaster position={'top-right'}>
            {
              alert.map((toast, key)=>{
                return(
                  <CToast
                    key={'toast' + key}
                    show={true}
                    autohide={5000}
                    fade={true}
                    className={toast.type}
                  >
                    <CToastHeader closeButton={true}>
                    {i18n.t('Thông báo')}
                    </CToastHeader>
                    <CToastBody>
                      {toast.message}
                    </CToastBody>
                  </CToast>
                )
              })
            }
          </CToaster>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route path="/admin" name="Home" render={props => <TheAdminLayoutWarranty {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayoutWarranty {...props}/>} />
              <Route path="*" name="Page 404" render={props => <Page404 {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    )
  }
}
function mapState(state) {
  const { alert } = state
  return { alert }
}

const actionCreators = {
  clearAlerts: alertActions.clear
}
const connectedApp = connect(mapState, actionCreators)(App)
export default connectedApp
