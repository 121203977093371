
export const appconfigActions = {
  setInfo,
  setItems,
  setMenuId
};
function setInfo(obj) {
  return { type: 'setInfo', obj };
}
function setItems(items) {
  return { type: 'setItems', items };
}
function setMenuId(id) {
  return { type: 'setMenuId', id };
}