import BaseService from './base.service'
export class ItemService extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('api/item', parameters)
  }
  pickup(data, status) {
    return this.submit('post', `${this.endpoint}/${status ? 'remove' : 'pickup'}`, data)
  }
}