import React from 'react'
import { connect } from 'react-redux';
export const VAction = connect((state) => {
  const { user } = state.authentication;
  return { user };
})(({ children, user }) => {
  return (
    <>
      {children}
    </>
  )
})

