import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withSize } from 'react-sizeme'
import { Avata } from "src/_components";
import classnames from 'classnames';

import Image from './Image';

const propTypes = {
  photosPerColumn: PropTypes.number,
  onPhotoPress: PropTypes.func,
};

const defaultProps = {
  photosPerColumn: 3,
  onPhotoPress: () => { },
};

class PhotoGrid extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: []
    }
    this.setSelected = this.setSelected.bind(this);

  }
  componentDidMount() {
    this.getColumnWidth();
  }
  setSelected(photo) {
    var { selected } = this.state
    if (this.props.multiple) {
      if (selected.includes(photo)) {
        this.setState({
          selected: selected.filter(e => e !== photo)
        })
      } else {
        this.setState({
          selected: selected.concat([photo])
        })
      }
    } else {
      this.setState({
        selected: [photo]
      })
    }
    setTimeout(() => {
      this.onChangeEvent()
    }, 100)
  }
  onChangeEvent() {
    if (this.props.setSelected) this.props.setSelected(this.state.selected, this.props.name)
  }
  getColumnWidth() {
    const {
      size,
      photosPerColumn,
    } = this.props;
    this.columnWidth = size.width / (this.getPhotos().length / photosPerColumn);
  }
  getPhotos() {
    return this.props.photos || []
  }
  renderColumns() {
    let columns = [];
    let index = 0;
    let current = 1;

    this.getPhotos().forEach(photo => {
      if (!columns[index]) {
        columns[index] = [];
      }
      columns[index].push(
        this.renderPhoto(photo)
      );
      if (current < 3) {
        current++;
      }
      else {
        current = 1;
        index++;
      }
    });

    return columns;
  }

  renderPhoto(photo) {
    const {
      onPhotoPress,
    } = this.props;

    return (
      <Image
        key={photo.src}
        onPress={onPhotoPress}
        columnSize={this.columnWidth}
        {...photo}
      />
    );
  }

  render() {
    const photos = this.getPhotos();
    var { selected } = this.state
    return (
      <div className="grid-container">
        <div className="grid">
          {photos.map((photo, index) => (
            <div key={index} onClick={() => this.setSelected(photo.src)} className={classnames(
              'grid-item-photo',
              selected.includes(photo.src) && 'selected',
            )}>
              <Avata src={photo.src} />
            </div>
          ))}
        </div>
      </div>
    );
  }
  // __render() {
  //   const columns = this.renderColumns();

  //   return (
  //     <div className="grid-container">
  //       <div className="grid">
  //         {columns.map((column, index) => (
  //           <div key={`.column${index}`} className="column">
  //             {column}
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   );
  // }
}

PhotoGrid.propTypes = propTypes;
PhotoGrid.defaultProps = defaultProps;

export default withSize()(PhotoGrid);
