import React from 'react'
import Select from 'react-select'
export const SelectBoxInput = (props) => {
  const applyCallback = (value) => {
    if (props.onChange)
      props.onChange(props.name, value.value);
  }
  const objValue = props.options?.find(e => e.value === props.value)
  return (
    <>
      {props.readonly ?
        <div className="select-val">{objValue ? objValue.label : ''}</div>
        :
        <Select
          className="zixndex1"
          value={objValue}
          options={props.options}
          name={props.name}
          onChange={applyCallback}
        />
      }
    </>
  )
}