
import CIcon from '@coreui/icons-react'
import { freeSet } from '@coreui/icons'
import { brandSet } from '@coreui/icons'
export const VIcon = (props) => {
  return (
    <>
      <CIcon
        height= {props.height || null}
        size={props.size || 'sm'}
        className = {props.className || ''}
        content={props.type === 'brand' ? brandSet[props.name] : freeSet[props.name]}
      />
    </>
  )
}
export const VIcon2 = (props) => {
  return (
    <>
      <CIcon
        height= {props.height || null}
        size={props.size || 'sm'}
        className = {props.className || ''}
        content={props.type === 'brand' ? brandSet[props.name] : freeSet[props.name]}
      />
    </>
  )
}