import QRCodeStyling from "qr-code-styling"

let counter = 0

export class QRCodeExt extends QRCodeStyling {

  constructor(options) {
    super(options)
    this.count = counter++
  }

  update(options) {
    super.update(options)
    if (this._svgDrawingPromise && options) {
      this._svgDrawingPromise.then(() => {
        if (!(this._svg instanceof SVGElement)) {
          return
        }

        const clipPath = this._svg.querySelector("#clip-path-dot-color")
        const rect = this._svg.querySelector(`rect[clip-path="url('#clip-path-dot-color')"]`)
        if (clipPath && rect) {
          clipPath.setAttribute("id", `clip-path-dot-color-${options.gid}`)
          rect.setAttribute("clip-path", `url('#clip-path-dot-color-${options.gid}')`)
        }
      })
    }
  }
}