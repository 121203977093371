/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { AdminOrderService } from "src/_services";
import { store } from 'src/_helpers'
function spawnNotification (body, icon, title) {
  var options = {
    body: body,
    icon: icon
  }
  var noti = null
  if (!('Notification' in window)) {
    console.log('This browser does not support desktop notification')
  } else if (Notification.permission === 'granted') {
    noti = new Notification(title, options)
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        noti = new Notification(title, options)
      }
    })
  }
  return noti
}
export const PendingCount = () => {
  const [val, setValue] = useState(0);
  let appconfig = store.getState().appconfg
  const getData = () => {
    new AdminOrderService({
      count: true
    }).noLoading().noError()
      .all()
      .then(response => {
        setValue(response.count)
        if (response.count) {
          spawnNotification(
            'Có ' + response.count + ' đơn đặt món/ dịch vụ đang chờ xử lý',
            (appconfig.config.find(e => e.code === 'LOGO') || {}).value,
            appconfig.site_title
          )
        }
        setTimeout(() => {
          getData()
        }, 30 * 1000);
      });
  }

  useEffect(() => {
    getData()
  }, []);
  return (<span className='pending-count ml-1'>{val}</span>)
}