import BaseService from './base.service'
import { storage, slugify } from 'src/_helpers'
export class CategoryService extends BaseService {
  /**
   * The constructor for the ArtistService.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super('api/category', parameters)
  }
  all() {
    return new Promise((resolve) => {
      var fromStorage = storage.get('ItemCategory')
      if (fromStorage) {
        return resolve(fromStorage)
      } else {
        return this.submit('get', `/${this.endpoint}/`).then((response) => {
          let results = response.results
          results.map(e => {
            e.paths = (e.path || '').split('/').filter(e => e)
            e.searchText = slugify(e.name)
            return e
          })
          response.results = results
          storage.set('ItemCategory', (response))
          resolve(response)
        })
      }
    })
  }
}