import { useState } from 'react';
// import { cssToObj } from 'src/_helpers'
import { CSSBuilder } from 'react-css-nocode-editor';
const styleString = `
  background-color: var(--primary);
  width: 200px;
`
// const styleString = `
//   background-color: var(--primary);
//   opacity: 0.5;
//   color: #ffffff;
//   padding-top: 40px;
//   text-align: center;
//   width: 200px;
//   height: 200px;
//   border-radius: 50%;
//   box-sizing: border-box;
// `

export const StyleInput = (props) => {
  const [style, setStyle] = useState(styleString);
  const onChange = (e) => {
    setStyle(e)
    if (props.onChange) props.onChange(props.name, e)
  }
  return (
    <div className='qr-builder text-left'>
      {/* <pre>{style}</pre> */}
      <CSSBuilder style={style} onChange={onChange} className="qr-builder-cpm" />
    </div>
  )
}
