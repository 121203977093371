import {
  CModal,
  CModalBody,
} from '@coreui/react';
import React, { Component } from 'react';
import { getAllListLanguage, getLang, getTranText2, getAppConfig } from 'src/_helpers'
// https://docs.dyspatch.io/localization/supported_languages/#:~:text=The%20code%20for%20English%20(United,%2D1%20Alpha%2D2%20standard.
export class LanguageChoses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasPopup: false
    };
    this.chosse = this.chosse.bind(this);
  }
  componentDidMount() {
    this.checkReq();
  }
  checkReq () {
    var langSelected = localStorage.getItem('lang')
    if (!langSelected && getAppConfig('lang_select_required') === '1') {
      this.setState({ hasPopup: true })
    }
  }
  chosse (l) {
    localStorage.setItem('lang', l.code)
    window.location.reload()
  }
  render() {
    const { hasPopup } = this.state
    const ListLang = getAllListLanguage()
    if (ListLang.length < 2) return <></>
    return (
      <div className='select-lang'>
        <span className='pointer' onClick={() => this.setState({ hasPopup: true })}><img alt='img' src={`/static/images/flag/${getLang()}.png`} /></span>
        {hasPopup && <CModal
          show={hasPopup}
          // backdrop={false}
          closeOnBackdrop={false}
          onClose={() => this.setState({ hasPopup: false })}
          color="primary"
          width={'250px'}
          size='sm'
        >
          <CModalBody>
          <div><b>{getTranText2('SELECT_LANG_TEXT')}</b></div>
          <div className='list-lang'>
              {ListLang.map(l => {
                  return <div key={l.code}><span className='pointer' onClick={() => this.chosse(l)}><img alt='img' src={`/static/images/flag/${l.code}.png`} /> {l.label}</span></div>
              })}
          </div>
          </CModalBody>
        </CModal>
        }
      </div>
    )
  }
}