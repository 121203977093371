import { store } from 'src/_helpers'
import _ from 'lodash';
import moment from "moment-timezone";
import { textNeedTran } from 'src/_constants'
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
export const retrieveImageFromClipboardAsBlob = (pasteEvent) => {
  if (pasteEvent.clipboardData === false) {
    return false;
  }

  var items = pasteEvent.clipboardData.items;

  if (items === undefined) {
    return false;
  }

  for (var i = 0; i < items.length; i++) {
    // Only paste if image is only choice
    if (items[i].type.indexOf("image") === -1) {
      return false;
    }
    // Retrieve image on clipboard as blob
    var blob = items[i].getAsFile();
    // load image if there is a pasted image
    if (blob !== null) {
      const reader = new FileReader();
      reader.onload = function (e) {
        // console.log('result', e.target.result);
      };
      reader.readAsDataURL(blob);
      return blob;
    }
  }
  return false;
}
export function cssToObj(css) {
  var obj = {}, s = css.toLowerCase().replace(/-(.)/g, function (m, g) {
    return g.toUpperCase();
  }).replace(/;\s?$/g,"").split(/:|;/g);
  for (var i = 0; i < s.length; i += 2) {
    obj[s[i].replace(/\s/g,"")] = s[i+1].replace(/^\s+|\s+$/g,"");
  }
  return obj;
}
export function triggerDownload(imgURI, filename) {
  const a = document.createElement('a');
  a.download = filename + '.png'; // filename
  a.target = '_blank';
  a.href = imgURI;
  a.dispatchEvent(new MouseEvent('click', {
    view: window,
    bubbles: false,
    cancelable: true
  }));
}
export function go2Login() {
  window.location.hash = '#/login'
}
export function isMenuOffline(menuData) {
  var nullStart = '01/01/2020'
  var nullEnd = '01/01/2030'
  var nullTimeStart = 0
  var nullTimeEnd = 2359
  var current = moment()
  var currentTime = parseInt(moment().format('HHmm'))
  if (!menuData.menu_config) return false
  var data = JSONParse(menuData.menu_config)
  if (!data) return false
  var configs = data.items
  if (!configs || configs.length === 0) return false
  const formatDta = process.env.REACT_APP_DATE_FORMAT
  // var msg = data.article
  configs = configs.map(e => {
    if (!e.time || (!e.time.start && !e.time.end)) {
      e.is_active = false
    } else {
      if (e.time.start) e.time.start = moment(e.time.start, formatDta)
      if (e.time.end) e.time.end = moment(e.time.end, formatDta)
      if (!e.time.start) e.time.start = moment(nullStart, formatDta)
      if (!e.time.end) e.time.end = moment(nullEnd, formatDta)
    }
    if (e.type === 'byhour' && !e.start_time && !e.end_time) {
      e.is_active = false
    } else {
      e.start_time = e.start_time ? parseInt(moment(e.start_time).format('HHmm')) : nullTimeStart
      e.end_time = e.end_time ? parseInt(moment(e.end_time).format('HHmm')) : nullTimeEnd
    }
    return e
  })
  var isOffline = configs.filter(e => e.is_active === true || e.is_active === 'true').find(e => {
    if (e.type === 'allday' && (e.time.start <= current && current <= e.time.end)) {
      return true
    }
    if (e.type === 'byhour' && (e.time.start <= current && e.time.end >= current)) {
      return currentTime > e.start_time && currentTime < e.end_time
    }
    return false
  })
  return isOffline
}
export function formatUrl(url) {
  if (!url) return url;
  if (isLocalhost()) {
    if (!url.includes('https://imonqr.com/') && !url.includes('blob')) return `https:/imonqr.com/${url}`
    if (!url.includes('https://imonqr.com') && !url.includes('blob')) return `https://imonqr.com${url}`
  }
  return url
}
export function getLang() {
  return localStorage.getItem('lang') || getAppConfig('DEFAULTLANGUAGE') || 'vi-VN'
}
export function isLocalhost() {
  return window.location.hostname === 'localhost'
}
export function getTranText2(k) {
  var defaultLanguage = getLang()
  var setupLanguage = getAppConfig(k + '_' + defaultLanguage)
  return setupLanguage || textNeedTran.find(e => e.code === k)?.text || k
}
export function getTranText(k, tranData) {
  if (!tranData) return null
  var current = getLang()
  return (tranData.find(e => e.code === `${k}_${current}`) || {}).value
}
export function getAllListLanguage() {
  let appconfig = store.getState().appconfg
  var defaultLanguage = getAppConfig('DEFAULTLANGUAGE') || 'vi-VN'
  var setupLanguage = getAppConfig('LANGUES')
  return appconfig.lang_config.filter(e => setupLanguage.includes(e.code) || defaultLanguage === e.code)
}
export function hasSetupLang() {
  var setupLanguage = JSONParse(getAppConfig('LANGUES')) || []
  return setupLanguage.length > 0
}
export function getListLanguage() {
  let appconfig = store.getState().appconfg
  var defaultLanguage = getAppConfig('DEFAULTLANGUAGE') || 'vi-VN'
  var setupLanguage = getAppConfig('LANGUES')
  return appconfig.lang_config.filter(e => e.code !== defaultLanguage && setupLanguage.includes(e.code))
}
export function mapStringTitle(src, dis) {
  return src.find(e => dis.includes(e.code))
}
export function getDataTranslate(item) {
  var listTran = getListLanguage()
  var dataTran = []
  for (const key in item) {
    if (mapStringTitle(listTran, key)) {
      dataTran.push({
        code: key,
        value: item[key]
      })
    }
  }
  return dataTran
}
export function getListLanguageSystem() {
  let appconfig = store.getState().appconfg
  var defaultLanguage = getAppConfig('DEFAULTLANGUAGE') || 'vi-VN'
  return appconfig.lang_config.filter(e => e.code !== defaultLanguage)
}
export function getAppConfig(k) {
  let appconfig = store.getState().appconfg
  return (appconfig.config.find(e => e.code === k) || { value: '' }).value
}
export function getConfig() {
  let appconfig = store.getState().appconfg
  return appconfig
}
export function converStrToJson(str, returnVal) {
  let jso = JSONParse(str)
  return jso || cloneObj(returnVal)
}
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}
export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export function removeAccents(str) {
  str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/ig, 'a')
  str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ|ë)/ig, 'e')
  str = str.replace(/(ì|í|ị|ỉ|ĩ)/ig, 'i')
  str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/ig, 'o')
  str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/ig, 'u')
  str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/ig, 'y')
  str = str.replace(/(đ)/ig, 'd')
  return str
}
export function convertObjectToQueryString(params) {
  return Object.keys(params).map(key => key + '=' + params[key]).join('&')
}
export function stringEquality(str, str2) {
  return cleanSearchKey(str) === cleanSearchKey(str2)
}
export function objEquality(str, str2) {
  return JSON.stringify(str) === JSON.stringify(str2)
}
export function cleanSearchKey(searchQuery) {
  if (!searchQuery) return ''
  let newValue = searchQuery.toLowerCase().trim()
  newValue = newValue.replace(/ +/g, '')
  newValue = removeAccents(newValue)
  return newValue
}
export function networkMenu(id) {
  return [
    {
      label: 'Đơn hàng',
      url: `/network/${id}/invoice`
    },
    {
      label: 'Sản phẩm',
      url: `/network/${id}/network-items`
    },
    {
      label: 'Báo cáo',
      url: `/network/${id}/report`
    },
    {
      label: 'Đại lý',
      url: `/network/${id}/profiles`
    },
    {
      label: 'Kho hàng',
      url: `/store`
    }
  ]
}
export function slugify(str) {
  var slug = str.toLowerCase();

  slug = slug.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/ig, 'a')
  slug = slug.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ|ë)/ig, 'e')
  slug = slug.replace(/(ì|í|ị|ỉ|ĩ)/ig, 'i')
  slug = slug.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/ig, 'o')
  slug = slug.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/ig, 'u')
  slug = slug.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/ig, 'y')
  slug = slug.replace(/(đ)/ig, 'd')
  slug = slug.replace(/[^a-zA-Z ]/g, '')

  slug = slug.replace(/ /gi, "-");
  slug = slug.replace(/----/gi, "-");
  slug = slug.replace(/---/gi, "-");
  slug = slug.replace(/--/gi, "-");
  return trimChar(slug, '-')
}
export function trimChar(string, charToRemove) {
  while (string.charAt(0) === charToRemove) {
    string = string.substring(1);
  }

  while (string.charAt(string.length - 1) === charToRemove) {
    string = string.substring(0, string.length - 1);
  }

  return string;
}
export function parseInt(str) {
  const parsed = Number.parseFloat(str)
  if (Number.isNaN(parsed)) {
    return false
  }
  return parsed
}
export function cloneObj(obj) {
  return JSONParse(JSON.stringify(obj))
}
export function JSONParse(str) {
  if (!str) return null
  try {
    return (JSON.parse(str))
  } catch (error) {
    console.log(error)
    return null
  }
}
export function randomIntFromInterval(min = 0, max = 100) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min);
}
export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv;
  }, {});
};
export const imageDimensions = file =>
  new Promise((resolve, reject) => {
    const img = new Image()

    // the following handler will fire after the successful loading of the image
    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img
      resolve({ width, height })
    }

    // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
    img.onerror = () => {
      reject('There was some problem with the image.')
    }

    img.src = file
  })
export function isAdminNetwork(networkId) {
  try {
    return store.getState().authentication.user.network_owners.includes(
      Number(networkId)
    );
  } catch (e) {
    return false
  }
}
export function compareArrs(x, y) {
  return _.intersection(x, y)
}
export function isSuperAdmin() {
  try {
    return store.getState().authentication.user.username.includes('thangdc')
  } catch (e) {
    return false
  }
}
export function hasPermissions(action) {
  if (isSuperAdmin()) return true
  const actions = [].concat(store.getState().authentication.user.roles)
  let elmAction = action
  if (typeof action !== 'object') {
    elmAction = [action]
  }
  const hasPermission = (compareArrs(actions, elmAction) || []).length > 0
  if (!action || hasPermission) return true;
  return false
}
// export function hasPermissions(action) {
//   try {
//     return store.getState().authentication.user.roles.includes(
//       action
//     );
//   } catch (e) {
//     return false
//   }
// }
export function hasActions(actions) {
  try {
    return store.getState().authentication.user.roles.includes(
      actions
    );
  } catch (e) {
    return false
  }
}
export function getCurrenProfile() {
  try {
    return store.getState().authentication.user.profile_info
  } catch (e) {
    return {}
  }
}
export function getCurrenUser() {
  try {
    return store.getState().authentication.user
  } catch (e) {
    return null
  }
}
export function getProfileNetwork() {
  try {
    return store.getState().authentication.networks
  } catch (e) {
    return null
  }
}
export function pythonDateToVnDate(strDate) {
  if (strDate)
    return moment(
      strDate,
      process.env.REACT_APP_PYTHON_DATE_FORMAT
    ).format(process.env.REACT_APP_DATE_FORMAT)
  return null
}
export function pythonDateToVnDateTime(strDate, format) {
  if (strDate)
    return moment(
      strDate,
      process.env.REACT_APP_PYTHON_DATETIME_FORMAT
    ).format(format || process.env.REACT_APP_DATETIME_FORMAT)
  return null
}
export function pythonDateToMoment(strDate) {
  if (strDate)
    return moment(
      strDate,
      process.env.REACT_APP_PYTHON_DATETIME_FORMAT
    )
  return null
}
export function vnDateTopythonDate(strDate) {
  if (strDate)
    return moment(
      strDate,
      process.env.REACT_APP_DATE_FORMAT
    ).format(process.env.REACT_APP_PYTHON_DATE_FORMAT)
  return null
}
export function vnDateTimeTopythonDate(strDatetime) {
  if (strDatetime)
    return moment(
      strDatetime,
      process.env.REACT_APP_DATETIME_FORMAT
    ).format(process.env.REACT_APP_PYTHON_DATETIME_FORMAT)
  return null
}
// export function dateRange(startDate, endDate) {
//   var start = startDate.split('/');
//   var end = endDate.split('/');
//   var startYear = parseInt(start[0]);
//   var endYear = parseInt(end[0]);
//   var dates = [];

//   for (var i = startYear; i <= endYear; i++) {
//     var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
//     var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
//     for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
//       var month = j + 1;
//       var displayMonth = month < 10 ? '0' + month : month;
//       dates.push([i, displayMonth, '01'].join('-'));
//     }
//   }
//   return dates;
// }
export function monthRange(startDate, endDate) {
  var start = startDate.split('/');
  var end = endDate.split('/');
  var startYear = parseInt(start[2]);
  var endYear = parseInt(end[2]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? '0' + month : month;
      dates.push(
        { display: `${displayMonth}-${i}`, month, year: i, type: 'ByMonth' }
      );
    }
  }
  if (dates.length === 1) return weekRange(startDate, endDate)
  return dates;
}

export function strDateToMoment(str, format = process.env.REACT_APP_DATE_FORMAT) {
  return moment(
    str,
    format
  )
}
function getDisplayDateRange(range) {
  var start = range[0].split('/')
  return `${start[0]}/${start[1]}`
}
function getDisplayWeekRange(range) {
  var start = range[0].split('/')
  var end = range[1].split('/')
  return `${start[0]} ~ ${end[0]}/${start[1]}`
}
export function dateRange(startDate, endDate) {
  var start = strDateToMoment(startDate)
  var end = strDateToMoment(endDate)
  var dates = [];
  while (start <= end) {
    var display = [start.format(process.env.REACT_APP_DATE_FORMAT), start.format(process.env.REACT_APP_DATE_FORMAT)]
    var objItem = {
      display: getDisplayDateRange(display),
      start: display[0],
      end: display[1],
      _end: start,
      type: 'ByDate'
    }
    dates.push(objItem)
    start = start.clone().add(1, 'days')
  }
  return dates
}
export function weekRange(startDate, endDate) {
  var start = strDateToMoment(startDate)
  var end = strDateToMoment(endDate)
  var dates = [];
  var _start = start.clone()
  while (start <= end) {
    var dayOfWeek = start.day()
    if (dayOfWeek % 7 === 0) {
      var display = [_start.format(process.env.REACT_APP_DATE_FORMAT), start.format(process.env.REACT_APP_DATE_FORMAT)]
      var objItem = {
        display: getDisplayWeekRange(display),
        start: display[0],
        end: display[1],
        _end: start,
        type: 'ByWeek'
      }
      _start = start.clone()
      dates.push(objItem)
    }
    start = start.clone().add(1, 'days')
  }
  if (dates.length === 1) return dateRange(startDate, endDate)
  var endOfArr = dates[dates.length - 1]
  if (endOfArr._end < end) {
    dates.push({
      display: getDisplayWeekRange([endOfArr._end.format(process.env.REACT_APP_DATE_FORMAT), end.format(process.env.REACT_APP_DATE_FORMAT)]),
      start: endOfArr._end.format(process.env.REACT_APP_DATE_FORMAT),
      end: end.format(process.env.REACT_APP_DATE_FORMAT),
      _end: end,
      type: 'ByWeek'
    })
  }
  return dates;
}
const MONTHS = [
  'Jan - 23',
  'Feb - 23',
  'Mar - 23',
  'Apr - 23',
  'May - 23',
  'Jun - 23',
  'Jul - 23',
  'Aug - 23',
  'Sep - 23',
  'Oct - 23',
  'Nov - 23',
  'Dec - 23'
];

export function months(config) {
  var cfg = config || {};
  var count = cfg.count || 12;
  var section = cfg.section;
  var values = [];
  var i, value;

  for (i = 0; i < count; ++i) {
    value = MONTHS[Math.ceil(i) % 12];
    values.push(value.substring(0, section));
  }

  return values;
}
const COLORS = [
  '#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba'
];

export function color(index) {
  return COLORS[index % COLORS.length];
}
export const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
};
export const downloadFileFromText = (filename, text) => {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}