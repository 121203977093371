export * from './alert.constants';
export * from './user.constants';
export * from './mesage.constants';
export * from './his.constants';
export * from './his.constants';
export * from './his.constants';
export * from './conditionType.constants';
export * from './validator.message';
export * from './Colors';
export const storeInvoiceType = [
  'Phiếu nhập hàng', 'Phiếu xuất hàng', 'Phiếu điều chuyển', 'Phiếu hủy', 'Phiếu tạo tự động'
]
export const paymentTypes = [
  'Tiền Mặt', 'Chuyển Khoản', 'COD', 'Công Nợ'
]
export const orderStatus = [
  'Tạo mới', 'Đã gửi, Chờ duyệt', 'Đã duyệt', 'Đang giao', 'Đã nhận', 'Đã hủy'
]
export const orderType = [
  'Đơn bán hàng', 'Đơn đặt hàng', 'Đơn đặt hàng khách lẻ', 'Trả hàng', 'Đơn bán hàng khách lẻ',
]
export const submenuConstants = {
  ADD: 'ADD_MENU',
  NEW: 'NEW_MENU',
  CLEAR: 'CLEAR'
};
export const transactionType = [
  {value: '', label: '-- Chọn --'},
  {value: '0', label: 'Doanh thu'},
  {value: '1', label: 'Chi phí nhập hàng'},
  {value: '2', label: 'Chi phí ship'},
  {value: '3', label: 'Thuế'},
  {value: '4', label: 'Nợ'},
  {value: '5', label: 'Trả nợ'},
]
export const orderStatusOption = [
  {value: '', label: 'Tất cả'},
  {value: '0', label: 'Tạo mới'},
  {value: '1', label: 'Xác nhận'},
  {value: '2', label: 'Hoàn thành'},
  {value: '3', label: 'Đã hủy'}
]
export const roomStatusOption = [
  {value: '', label: 'Tất cả'},
  {value: '0', label: 'Close'},
  {value: '1', label: 'Open'}
]
export const paymentMethod = [
  {color: 'warning', value: 'ROOMCHAGER', label: 'ROOM CHAGER'},
  {color: 'info', value: 'CASH', label: 'CASH'},
  {color: 'primary', value: 'VISAMASTER', label: 'VISA, MASTER'},
  {color: 'secondary', value: 'BANKING', label: 'BANKING'}
]
export const textNeedTran = [
  {
    text: 'Kính chào quý khách phòng',
    code: 'text_1'
  },{
    text: 'Kính mời quý khách chọn món',
    code: 'text_2'
  },{
    text: 'Vui lòng kiểm tra món đã đặt',
    code: 'text_3'
  },{
    text: 'Đặt thêm',
    code: 'text_4'
  },{
    text: 'Đặt hàng',
    code: 'text_5'
  },{
    text: 'Tổng',
    code: 'text_6'
  },{
    text: 'Nhập ghi chú',
    code: 'text_7'
  },{
    text: 'CẢM ƠN QUÝ KHÁCH ĐÃ GỌI MÓN',
    code: 'text_8'
  },{
    text: 'VUI LÒNG CHÚ Ý ĐIỆN THOẠI CHÚNG TÔI XIN PHÉP SẼ GỌI LẠI ĐỂ XÁC NHẬN ĐƠN HÀNG TRONG VÀI PHÚT',
    code: 'text_9'
  },{
    text: 'TRÂN TRỌNG CẢM ƠN',
    code: 'text_10'
  },{
    text: 'Thông báo',
    code: 'text_11'
  },{
    text: 'Qúy khách vui lòng sử dụng wifi khách sạn hoặc nhập thông tin dưới đây để đặt món',
    code: 'text_12'
  },{
    text: 'Tên của bạn',
    code: 'text_13'
  },{
    text: 'Số điện thoại',
    code: 'text_14'
  },{
    text: 'Vui lòng ký xác nhận',
    code: 'text_15'
  },{
    text: 'Vui lòng để lại vài dòng cảm nhận hoặc góp ý về chất lượng dịch vụ',
    code: 'text_16'
  },{
    text: 'Gửi phản hồi',
    code: 'text_17'
  },{
    text: 'Chọn phương thức thanh toán',
    code: 'text_18'
  },{
    text: 'Tính vào Phòng',
    code: 'ROOM CHAGER'
  },{
    text: 'Tiền mặt',
    code: 'CASH'
  },{
    text: 'Thẻ',
    code: 'VISA, MASTER'
  },{
    text: 'Chuyển khoản',
    code: 'BANKING'
  },{
    text: 'Vui lòng chọn ngôn ngữ',
    code: 'SELECT_LANG_TEXT'
  },{
    text: 'Thêm vào giỏ hàng',
    code: 'ADD_TO_CART'
  }, {
    text: 'Sửa',
    code: 'edit_cart_item'
  }, {
    text: 'Thành tiền',
    code: 'thanh_tien'
  }, {
    text: 'VAT',
    code: 'VAT'
  }, {
    text: 'SPEC',
    code: 'SPEC'
  }, {
    text: 'SVC',
    code: 'SVC'
  }, {
    text: 'Đồng ý',
    code: 'REMOVE_CART'
  }, {
    text: 'Chọn mới',
    code: 'NEW_CART'
  }, {
    text: '* Vui lòng chọn ít nhất 1 món',
    code: 'REQUIRED_NOTE_TEXT'
  }, {
    text: '* Vui lòng quét lại mã QR để đặt món',
    code: 'QR_EXPIRE_NOTE'
  }, {
    text: 'Gửi yêu cầu',
    code: 'SEND_REQUEST'
  }, {
    text: 'Thời gian',
    code: 'ORDER_TIME'
  }, {
    text: 'REF TITLE',
    code: 'REF_TITLE'
  }, {
    text: 'REF TEXT',
    code: 'REF_TEXT'
  },
]
