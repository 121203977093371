import React, { useState, useEffect } from "react";
import { Children } from 'react';
import {
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter
} from "@coreui/react"

export const BaseModal = ({ children, title, size, color, open, onClose }) => {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setIsOpen(open)
  }, [open]);
  return (
    <CModal
      show={isOpen}
      onClose={() => { setIsOpen(); onClose() }}
      size={size || 'lg'}
      color={color || 'primary'}
    >
      {title && <CModalHeader closeButton>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>}
      <CModalBody className="v-tabs">
        {Children.map(children, child => {
          return child.props.type === 'content' ? child : <></>
        }
        )}
      </CModalBody>
      <CModalFooter className="d-flex justify-content-between">
        <CButton
          color="secondary"
          onClick={() => { setIsOpen(); onClose() }}
        >Hủy</CButton>
        {Children.map(children, child => {
          return child.props.type === 'footer' ? child : <></>
        }
        )}
      </CModalFooter>
    </CModal>
  );
};
